import {Injectable} from '@angular/core';

export interface BadgeItem {
  type: string;
  value: string;
}

export interface ChildrenItems {
  state: string;
  target?: boolean;
  name: string;
  type?: string;
  children?: ChildrenItems[];
}

export interface MainMenuItems {
  state: string;
  short_label?: string;
  main_state?: string;
  target?: boolean;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  children?: ChildrenItems[];
}

export interface Menu {
  label: string;
  main: MainMenuItems[];
}

const MENUITEMS = [
  {
    label: '',
    main: [
      {
        state: 'dashboard',
        short_label: 'D',
        name: 'Dashboard',
        type: 'link',
        icon: 'ti-home'
      },

    ],
  },
  {
    label: 'User Managment',
    main: [

      {
        state: 'user',
        short_label: 'B',
        name: 'User',
        type: 'sub',
        icon: 'ti-user',
        children: [
          {
            state: 'manage-user',
            name: 'Manage User',
            type: 'link'
          },
          {
            state: 'manage-department',
            name: 'Manage Department',
            type: 'link'
          },
          {
            state: 'manage-role',
            name: 'Manage User Role',
            type: 'link'
          },
          {
            state: 'manage-privileges',
            name: 'Manage Use privileges',
            type: 'link'
          }
        ]
      },
      // {
      //   state: 'notifications',
      //   short_label: 'n',
      //   name: 'Notifications',
      //   type: 'link',
      //   icon: 'ti-crown'
      // },
    ],
  },

  {
    label: 'Manage Animals',
    main: [
      {
        state: 'manage-animals',
        short_label: 'A',
        name: 'Manage Animals',
        type: 'sub',
        icon: 'ti-map-alt',
        children: [
          {
            state: 'manage-animals',
            name: 'Manage Animals',
            type: 'link'
          },

          {
            state: 'animals-shed',
            name: 'Animals Shed',
            type: 'sub'
          },

          {
            state: 'animal-shed-history',
            name: 'MOve Animal to Shed',
            type: 'sub'
          },

          {
            state: 'manage-breeding',
            name: 'Manage Breeding',
            type: 'link'
          },
          {
            state: 'healthRecord',
            name: 'Manage Health',
            type: 'sub'
          },
          {
            state: 'weightRecord',
            name: 'Manage Weight',
            type: 'sub'
          },



        ]
      }
    ]
  },
  {
    label: 'Manage Feed',
    main: [
      {
        state: 'manage-feedDesign',
        short_label: 'A',
        name: 'Manage Feed',
        type: 'sub',
        icon: 'ti-map-alt',
        children: [
          {
            state: 'manage-feedDesign',
            name: 'Design Feed for Shed',
            type: 'link'
          },
          {
            state: 'add-dailyFeedDistToShed',
            name: 'Apply Daily feed to Shed',
            type: 'sub'
          },
          {
            state: 'list-dailyFeedDistribution',
            name: 'Daily Feed List',
            type: 'sub'
          },




        ]
      }
    ]
  },
  {
    label: 'Manage Milk',
    main: [
      {
        state: 'manage-milk',
        short_label: 'A',
        name: 'Manage Milk',
        type: 'sub',
        icon: 'ti-map-alt',
        children: [

          {
            state: 'add-milk',
            name: 'Add Milk',
            type: 'sub'
          },
          {
            state: 'add-milk-table-data',
            name: 'Add Milk Table',
            type: 'sub'
          },
          {
            state: 'manage-milk',
            name: 'Milk List',
            type: 'link'
          },
          {
            state: 'daily-milkRecord',
            name: 'Daily Milk Report',
            type: 'sub'
          },
        ]
      }
    ]
  },
  {
    label: 'Expense Managment',
    main: [

      {
        state: 'expense',
        short_label: 'E',
        name: 'Expense',
        type: 'sub',
        icon: 'ti-money',
        children: [
          {
            state: 'manage-expense',
            name: 'Manage Expense',
            type: 'link'
          },
        ]
      },

    ],
  },
  {
    label: 'Farm Store Managment',
    main: [

      {
        state: 'manage-stock',
        short_label: 'E',
        name: 'Manage Stock',
        type: 'sub',
        icon: 'ti-money',
        children: [
          {
            state: 'manage-stock',
            name: 'Available Stock',
            type: 'link'
          },
          {
            state: 'list-purchase',
            name: 'Stock Purchase ',
            type: 'sub'
          },

          {
            state: 'supplier',
            name: 'Manage Supplier',
            type: 'sub'
          },

          {
            state: 'supplier-ledger',
            name: 'Supplier Ledger',
            type: 'sub'
          },
          {
            state: 'manage-products',
            name: 'Manage Products',
            type: 'sub'
          },
          {
            state: 'product-ledger',
            name: 'Product Ledger',
            type: 'sub'
          },
        ]
      },

    ],
  },
  {
    label: 'Manage Settings',
    main: [

      {
        state: 'setting',
        short_label: 'S',
        name: 'Settings',
        type: 'sub',
        icon: 'ti-settings',
        children: [
          {
            state: 'manage-commonValues',
            name: 'Manage Common Values',
            type: 'link'
          },
        ]
      },

    ],
  },
  {
    label: 'LogOut',
    main: [
      {
        state: 'logout',
        short_label: 'L',
        name: 'Logout',
        type: 'logOut',
        icon: 'ti-layout-sidebar-left'
      }
    ]
  },

  //     {
  //       state: 'authentication',
  //       short_label: 'A',
  //       name: 'Authentication',
  //       type: 'sub',
  //       icon: 'ti-id-badge',
  //       children: [
  //         {
  //           state: 'login',
  //           type: 'link',
  //           name: 'Login',
  //           // target: true
  //         }, {
  //           state: 'registration',
  //           type: 'link',
  //           name: 'Registration',
  //           target: true
  //         }
  //       ]
  //     },
  //     {
  //       state: 'user',
  //       short_label: 'U',
  //       name: 'User Profile',
  //       type: 'link',
  //       icon: 'ti-user'
  //     }
  //   ]
  // },
  // {
  //   label: 'Other',
  //   main: [
  //     {
  //       state: '',
  //       short_label: 'M',
  //       name: 'Menu Levels',
  //       type: 'sub',
  //       icon: 'ti-direction-alt',
  //       children: [
  //         {
  //           state: '',
  //           name: 'Menu Level 2.1',
  //           target: true
  //         }, {
  //           state: '',
  //           name: 'Menu Level 2.2',
  //           type: 'sub',
  //           children: [
  //             {
  //               state: '',
  //               name: 'Menu Level 2.2.1',
  //               target: true
  //             },
  //             {
  //               state: '',
  //               name: 'Menu Level 2.2.2',
  //               target: true
  //             }
  //           ]
  //         }, {
  //           state: '',
  //           name: 'Menu Level 2.3',
  //           target: true
  //         }, {
  //           state: '',
  //           name: 'Menu Level 2.4',
  //           type: 'sub',
  //           children: [
  //             {
  //               state: '',
  //               name: 'Menu Level 2.4.1',
  //               target: true
  //             },
  //             {
  //               state: '',
  //               name: 'Menu Level 2.4.2',
  //               target: true
  //             }
  //           ]
  //         }
  //       ]
  //     },
  //     {
  //       state: 'simple-page',
  //       short_label: 'S',
  //       name: 'Simple Page',
  //       type: 'link',
  //       icon: 'ti-layout-sidebar-left'
  //     }
  //   ]
  // }, {
  //   label: 'Support',
  //   main: [
  //     {
  //       state: 'Upgrade To Pro',
  //       short_label: 'U',
  //       external: 'https://codedthemes.com/item/guru-able-admin-template/',
  //       name: 'Upgrade To Pro',
  //       type: 'external',
  //       icon: 'ti-layout-list-post',
  //       target: true
  //     }
  //   ]
  // }
];

@Injectable()
export class MenuItems {
  getAll(): Menu[] {
    return MENUITEMS;
  }

  /*add(menu: Menu) {
    MENUITEMS.push(menu);
  }*/
}
