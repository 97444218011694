import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class AccountService {
  baseUrl=environment.WebApiBaseUrl;
  constructor(private http:HttpClient,private router:Router) { }


  Login(login:LoginModel):Observable<any>
  {
    debugger
  return this.http.post(this.baseUrl+'/Accounts/LoginUser',login)
  }
  GetCred() {
    return this.http.get(`${this.baseUrl}/Accounts/GetRememberMeCredentials`);
  }
  logout() {
    localStorage.removeItem('UserProfile');
        this.router.navigateByUrl("/account");
    }
    setPassword(setPassword: SetPassword) {

      console.log('setPassword', setPassword)

      return this.http.post(`${this.baseUrl}/Accounts/SetPassword`, setPassword);
    }
    confirmEmail(userid: any, token: string) {
      return this.http.get(`${this.baseUrl}/Accounts/Confirm?userId=${userid}&token=${token}`);
    }
    resetPassword(resetPassword: ResetPassword) {
      return this.http.post(`${this.baseUrl}Api/Accounts/ResetPassword`, resetPassword);
    }
    ForgotPsw(forgotPswModel: ForgotPassword) {
      return this.http.post(`${this.baseUrl}/Accounts/ForgotPassword`, forgotPswModel);
    }
}
export class LoginModel {
  userName: string;
  password: string;
  rememberMe: boolean;
  browser: string;
  os: string;
  iPAddress: string;
  deviceType:string;
  userAgent:string;
  constructor() {
    this.userName = '';
    this.password = '';
    this.rememberMe = false;
    this.browser = '';
    this.os = '';
    this.iPAddress = '';
    this.deviceType='';
    this.userAgent='';
  }
}
export class UserDetails {
  id: string;
  email: string;
  firstName: string = "";
  lastName: string = "";
  userType: string;
  fullName = this.firstName + ' ' + this.lastName;
  companyId: number;
  roleCode: string;
}
export class SetPassword {
  password: string;
  confirmPassword: string;
  userId: any;
  token: any;
  ResetToken: any;
  constructor() {
    this.password = "";
    this.confirmPassword = "";
    this.userId = null;
    this.token = "";
    this.ResetToken = "";
  }
}
export class ResetPassword {
  password: string;
  confirmPassword: string;
  userId: any;
  resetToken: any;
  constructor() {
    this.password = "";
    this.confirmPassword = "";
    this.userId = null;
  }
}
export class ForgotPassword {
  email: string;
  siteTitle: string;
  siteImg: string;
  companyURL: string;
  companyID: number;
  constructor() {
    this.email = '';
    this.siteTitle = '';
    this.siteImg = '';
    this.companyURL = '';
    this.companyID = 0;
  }
}
