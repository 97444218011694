import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Welcome ! Quran Institute Pakistan';
  isLogin:boolean=true;
  constructor(private router: Router) { }

  ngOnInit() {
    if (localStorage.getItem('UserProfile')) {
      this.isLogin = true;
    }
    else{
      this.isLogin=false;
    }
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }
}
