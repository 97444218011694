import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs/Rx';
import { catchError } from 'rxjs/operators';

import { Router } from '@angular/router';
import { AlertifyService } from '../Services/Alertify.service';
import { AccountService } from '../Views/account/account.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
 constructor(private router: Router,private loginser:AccountService
  ,private alertify:AlertifyService
  ) { }

 intercept(request: HttpRequest<any>, newRequest: HttpHandler): Observable<HttpEvent<any>> {

 return newRequest.handle(request).pipe(catchError(err =>{
  debugger;
 if (err.status === 401) {
 //if 401 response returned from api, logout from application & redirect to login page.
 //this.authenticationService.logout();
 this.loginser.logout();

 }
 else{
const message="We are sorry for inconvenience ,Please Coordinate with System admin";
this.alertify.Erroe(message);
}


 const error = err.error.message || err.statusText;
 return Observable.throw(error);
 }));
 }
}

