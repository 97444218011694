import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { SharedService, transRequestModel } from '../shared.service';
import { first } from 'rxjs/operators';
import { AlertifyService } from '../../Services/Alertify.service';

@Component({
  selector: 'app-transaction-request-popup',
  templateUrl: './transaction-request-popup.component.html',
  styleUrls: ['./transaction-request-popup.component.scss']
})
export class TransactionRequestPopupComponent implements OnInit {
  @ViewChild('transRequestmodal', { static: false }) transRequestmodal: ModalDirective;
  @Output() isRequestForwarded = new EventEmitter<void>();
  id:number=0;
  requestNo:string=null;
  title:string='Add New';
  reqData:any=null;
  loader:boolean=false;
  constructor(private FB:UntypedFormBuilder,
    private rout:Router,private router:ActivatedRoute, private commonServ:SharedService,private alertify:AlertifyService) {

  }

  transReqForm: UntypedFormGroup;

  transRequestModel:transRequestModel;
    typesubmitted:boolean;
  ngOnInit(): void {
    debugger
this.createtransRequestForm();
}

  createtransRequestForm()   //this is anotherway for validating
  {
    this.transReqForm=this.FB.group({
      varreason:new UntypedFormControl(null,Validators.required),
      varRequestID:new UntypedFormControl(null,Validators.required)

    });
  }

//getter mothod for all controls
get reason()
{
  return this.transReqForm.get("varreason") as UntypedFormControl
}
get requestID()
{
  return this.transReqForm.get("varRequestID") as UntypedFormControl
}
EditForm(id:any)
  {
    debugger;
    this.loader=true;
    this.commonServ.EditCommonType(id)
      .pipe(first())
      .subscribe((x) => this.transReqForm.patchValue(x)
      );
    this.loader=false;
  }
SaveForm() {
  debugger;
  if (this.transReqForm.valid) {
    this.loader=true;
    this.commonServ
      .transRequestmodal(this.transreqMoelData())
      .subscribe((response: any) => {
        if (response.statusCode == 200) {
          this.transReqForm.reset();
          this.loader=false;
          this.alertify.Success(response.responseMessage);
          this.isRequestForwarded.emit();
          this.close();
        }
        else {
          this.alertify.Erroe(response.responseMessage);
        }
      }, error => {
        this.loader=false;
      });
  } else {
    this.alertify.Erroe('Form is Invalid Please fill the form Correctly');
  }
}

  transreqMoelData():transRequestModel
{
  debugger
return this.transRequestModel={
id:this.id,
varreason:this.reason.value,
varRequestID:this.requestID.value,
varrequestData:this.reqData,
}
}
showpopup(changeMOdalValue:any,reqNo:string,id?:number) {
  debugger;
  if(id)
  {
this.id=id;
this.title="Edit"
this.EditForm(this.id);
  }
  this.requestNo=reqNo;
this.reqData=changeMOdalValue;
this.requestID.setValue(this.requestNo);
 this.transRequestmodal.show();
}

close() {
  this.transRequestmodal.hide();
}
}
