import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-confirmation-popup',
  templateUrl: './confirmation-popup.component.html',
  styleUrls: ['./confirmation-popup.component.css']
})
export class ConfirmationPopupComponent implements OnInit {

  @Input() title: string;
  @Input() message: string;
  @Input() btnOkText: string;
  @Input() btnCancelText: string;
  @Input() showCancel: boolean;
  @Input() html: boolean;

  confirmed = false;

  constructor(public bsModalRef: BsModalRef) {
    this.title='';
    this.message='';
   }

  ngOnInit() {
    debugger;
    debugger
    this.title='';
    this.message='';
  }

  cancel() {
    this.confirmed = false;
    this.close();
  }

  ok() {
    this.confirmed = true;
    this.close();
  }

  close() {
    this.bsModalRef.hide();
  }
}
