import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './Interceptor/AuthGuard';
import {AdminComponent} from './layout/admin/admin.component';
import {AuthComponent} from './layout/auth/auth.component';

const routes: Routes = [
  {
      path: 'account', loadChildren: () => import('./Views/account/account.module').then(m => m.AccountModule),
      pathMatch: "prefix"
    },
    {
    path: '',
    component: AdminComponent,
     children: [
      {
        path: '', redirectTo: 'dashboard',
        pathMatch: "full"
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./pages/dashboard/dashboard-default/dashboard-default.module').then(m => m.DashboardDefaultModule),
        canActivate: [AuthGuard], pathMatch: "prefix"
      },

  {
    path: 'user',
    loadChildren: () => import('./pages/user/profile/profile.module').then(m => m.ProfileModule),pathMatch: "prefix"
  },

  {
    path: 'manage-user', loadChildren: () => import('./Views/manage-user/manage-user.module').then(m => m.ManageUserModule),pathMatch: "prefix"
  ,canActivate: [AuthGuard]
  },
  {
    path: 'manage-role', loadChildren: () => import('./Views/manage-user-role/manage-user-role.module').then(m => m.ManageUserRoleModule),
    canActivate: [AuthGuard], pathMatch: "prefix"
  },
  {
    path:'manage-privileges',loadChildren:()=>import('./Views/manage-user-privileges/manage-user-privileges.module').then(m=>m.ManageUserPrivilegesModule),
  canActivate: [AuthGuard], pathMatch: "prefix"
  },
  {
    path: 'manage-department', loadChildren: () => import('./Views/manage-department/manage-department.module').then(m => m.ManageDepartmentModule),
    canActivate: [AuthGuard],pathMatch: "prefix"
  },
  {
    path: 'manage-expense', loadChildren: () => import('./Views/manage-expense/manage-expense.module').then(m => m.ManageExpenseModule)
    ,canActivate: [AuthGuard], pathMatch: "prefix"
  },
  {
    path: 'manage-breeding', loadChildren: () => import('./Views/DaryManagment/animals-breeding/animals-breeding.module').then(m => m.AnimalsBreedingModule)
    ,canActivate: [AuthGuard], pathMatch: "prefix"
  },
  {
    path: 'manage-animals', loadChildren: () => import('./Views/DaryManagment/animals-managment/animals-managment.module').then(m => m.AnimalsManagmentModule)
    ,canActivate: [AuthGuard], pathMatch: "prefix"
  },
  {
    path: 'manage-milk', loadChildren: () => import('./Views/DaryManagment/animals-milking/animals-milking.module').then(m => m.AnimalsMilkingModule)
    ,canActivate: [AuthGuard], pathMatch: "prefix"
  },
  {
    path: 'manage-feedDesign', loadChildren: () => import('./Views/DaryManagment/animals-feed/animals-feed.module').then(m => m.AnimalsFeedModule)
    ,canActivate: [AuthGuard], pathMatch: "prefix"
  },
  {
    path: 'manage-stock', loadChildren: () => import('./Views/DaryManagment/farm-stock/farm-stock.module').then(m => m.FarmStockModule)
    ,canActivate: [AuthGuard], pathMatch: "prefix"
  },
  {
    path: 'manage-commonValues', loadChildren: () => import('./Views/common/commonTypes.module').then(m => m.commonTypes)
    ,canActivate: [AuthGuard], pathMatch: "prefix"
  },

  // {
  //         path: 'dashboard',
  //         loadChildren: () => import('./pages/dashboard/dashboard-default/dashboard-default.module').then(m => m.DashboardDefaultModule)
  //         //, canActivate: [AuthGuard]
  // },
  { path: '**', redirectTo: 'dashboard' }// if url goes 404
     ]

     }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
