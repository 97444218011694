import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  baseUrl=environment.WebApiBaseUrl;
  constructor(private http:HttpClient) { }

  GetCommonTypeList(moduleId,subModuleId,listFilter, sortColumn, sortDir, pageIndex, pageSize): Observable<any> {

    return this.http.get(this.baseUrl+`/ComonTypes/CommontTypesList?moduleId=${moduleId}&subModuleId=${subModuleId}&type=${listFilter}&sortColumn=${sortColumn}&sortDir=${sortDir}&page=${pageIndex}&pageSize=${pageSize}&filter=''`);

  }
  DeleteCommonType(id:any)
  {
  return this.http.delete(this.baseUrl+`/ComonTypes/DeleteCommontType?id=${id}`);
  }
  DeleteSelectedCommonType(ids: string): Observable<any> {
    return this.http.post(this.baseUrl+`/ComonTypes/DeleteMultipleCommontType`, null, {
      params: { ids },
    });
  }
  transRequestmodal(transreq:transRequestModel)
  {

    return this.http.post(this.baseUrl+'/TransactionEditRequest/GenerateRequest',transreq);
  }
  EditCommonType(id:any):Observable<any>
  {

    return this.http.get(this.baseUrl+'/ComonTypes/EditCommontType?id='+id+'');
  }
  GetModuleDD():Observable<any>{

    return this.http.get(this.baseUrl+'/ComonTypes/GetModuleDD');
  }
  GetSubModuleDD(moduleId:number):Observable<any>{

    return this.http.get(this.baseUrl+'/ComonTypes/GetSubModuleDD?moduleId='+moduleId+'');
  }
  GetCommonValuesDD(subModuleId:number):Observable<any>{

    return this.http.get(this.baseUrl+'/ComonTypes/GetCommonValuesDD?subModuleId='+subModuleId+'');
  }
  GetCommonTypesDropdown(type:any):Observable<any>{

      return this.http.get(this.baseUrl+'/ComonTypes/GetCommonTypeByTypeName?type='+type+'');
    }
    roundToTwoDecimals(value: number): number {
      return Math.round((value + Number.EPSILON) * 100) / 100;
    }
}
export const lstPageSize=[
  {value:10,text:'10'},
  {value:20,text:'20'},
  {value:30,text:'30'},
];

export class transRequestModel {
  id:number
  varRequestID:string
  varrequestData:string
  varreason:string
}
export interface IDD
{
  value:number,
  text:string
}
