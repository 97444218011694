import { Injectable } from '@angular/core';
import * as Alertfy from 'alertifyjs';

@Injectable({
  providedIn: 'root'
})
export class AlertifyService {

constructor() {  Alertfy.set('notifier','position', 'top-right')}

Success(message:string)
{

  Alertfy.success(message);
}
Erroe(message:string)
{
  Alertfy.error(message);

}
Warning(message:string)
{
  Alertfy.warning(message);
}

// Confirm(title:string,message:string)
// {
//  //return Alertfy.confirm(message);
//  var bool:boolean=false;
// //  Alertfy.confirm(title, message, function(){ bool=true },function(){bool=false});
// // alert(bool);
// //Alertfy.confirm('a callback will be invoked on cancel.').set('oncancel', function(closeEvent){ Alertfy.error('Cancel');} )
// Alertfy.confirm('a callback will be invoked on ok.').set('onok', function(closeEvent){ return true} );

// }
confirm(title: string, message: string, okCallback: () => any, cancelCallback: () => any) {
  Alertfy.confirm(title, message, () => { okCallback(); }
              , () => { cancelCallback(); });
}
}
